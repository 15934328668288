<template>
  <div class="bodyer">
    <div class="main-context">
      <div class="bodyer-box">
        <div class="news">
          <div class="news-time-icon">
            <el-tag type="info">
              <i class="el-icon-time" />
              2021年
            </el-tag>
          </div>
          <div v-for="(item, index) in newsList" :key="index" class="new" @click="open(item)">
            <div class="news_image">
              <img :src="item.imgUrl">
            </div>
            <div class="news_info">
              <div class="news_title">
                {{ item.title }}
              </div>
              <div class="news_content">
                {{ item.shortContent }}
              </div>
              <div class="news_time">
                {{ item.createTime }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog v-if="newsInfo" :title="newsInfo.title" width="80%" :visible.sync="newsDialogVisible">
      <div style="margin-bottom: 40px;" v-html="newsInfo.context" />
      <div>发布时间：{{ newsInfo?newsInfo.createTime:'' }}</div>
    </el-dialog>
  </div>
</template>

<script>
import { list } from '@/api/news'

export default {
  data() {
    return {
      newsInfo: null,
      newsDialogVisible: false,
      newsList: []
    }
  },
  created() {
    this.getNewsList()
  },
  methods: {
    getNewsList() {
      list({}).then(response => {
        this.newsList = response.data.list.map(m => {
          let shortContent = m.context.replace(/<[^>]+>/g, '').replace(/&nbsp;/ig, '')
          shortContent = shortContent.length > 165 ? shortContent.substring(0, 165) + '...' : shortContent
          return {
            ...m,
            shortContent
          }
        })
      })
    },
    open(item) {
      this.newsInfo = item
      this.newsDialogVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
  .bodyer {
    .bodyer-box {
      padding-top: 70px;

      .news {
        padding: 0 80px;

        .news-time-icon {
          margin-bottom: 20px;
        }

        .new {
          cursor: pointer;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-content: flex-start;
          align-items: flex-start;
          margin-bottom: 30px;

          .news_image {
            width: 220px;
            height: 150px;

            img {
              width: 200px;
              height: 150px;
            }
          }

          .news_info {
            flex: 1;

            .news_title {
              font-size: 24px;
              font-weight: bold;
              color: #17254c;
              margin-bottom: 20px;
            }
            .news_content {
              font-size: 16px;
              font-weight: 400;
              color: #17254c;
              line-height: 24px;
              opacity: 0.8;
              margin-bottom: 10px;
            }
            .news_time {
              font-size: 16px;
              font-weight: 400;
              color: #17254c;
              opacity: 0.5;
            }
          }
        }
      }
    }
  }
</style>
